import { Card, Typography, Avatar, Box, Chip } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { getFormattedDateAndTime } from "utils/getFormattedDateAndTime"
import classNames from "classnames"
import StarIcon from "@mui/icons-material/Star"
import { getFirstLetter } from "utils/getFirstLetter"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: theme.spacing(1.5),
    boxShadow: "none",
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      margin: theme.spacing(0, 1),
    },
    [theme.breakpoints.down(330)]: {
      padding: theme.spacing(1, 0),
      margin: 0,
    },
  },
  caregiverInfo: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 1),
    },
  },
  avatarContainer: {
    paddingLeft: theme.spacing(0.5),
    maxWidth: "40%",
    display: "flex",
    flexDirection: "column",
  },
  infoContainer: {
    marginLeft: theme.spacing(3),
    minWidth: "60%",
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.light_gray}`,
  },
  avatar: {
    position: "relative",
    alignSelf: "center",
    width: 80,
    height: 80,
    marginBottom: theme.spacing(1),
  },
  recommendedAvatar: {
    borderRadius: "50%",
    boxShadow: `0 0 0 4px ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.main,
  },
  recommendedChip: {
    height: "20px",
    fontSize: "0.6rem",
    letterSpacing: "0.0375rem",
    fontWeight: 500,
    marginBottom: theme.spacing(0.5),
    "& .MuiChip-icon": {
      color: theme.palette.primary.light,
      fontSize: "0.8125rem",
      margin: theme.spacing(-0.25, -0.625, 0, 0.75),
    },
  },
  caregiverTitle: {
    fontSize: "1.1rem",
    fontWeight: "700",
    wordBreak: "break-word",
  },
  availableTimeContainer: {
    paddingBottom: theme.spacing(0.5),
  },
  availableTimeText: {
    fontSize: "0.85rem",
    fontWeight: 500,
  },
  seeAllTimesText: {
    fontStyle: "italic",
    color: theme.palette.primary.main,
    fontWeight: "bold",
    fontSize: "0.7rem",
  },
}))

const CaregiverCard = ({ caregiver, onCaregiverSelect }) => {
  const classes = useStyles()
  const {
    firstName = "",
    lastName = "",
    avatarUrl = "",
    slot,
    recommended,
  } = caregiver
  const slotConsideringDstCalculation = new Date(slot?.start)

  return (
    <Card
      className={classes.root}
      data-testid="caregiver_card"
      onClick={() => onCaregiverSelect(caregiver)}
    >
      <Box className={classes.caregiverInfo}>
        <Box className={classes.avatarContainer}>
          <Avatar
            src={avatarUrl}
            className={classNames(classes.avatar, {
              [classes.recommendedAvatar]: recommended,
            })}
          />
        </Box>
        <Box className={classes.infoContainer}>
          {recommended && (
            <Chip
              icon={<StarIcon />}
              label="Rekommenderad"
              color="primary"
              size="small"
              className={classes.recommendedChip}
            />
          )}
          <Typography className={classes.caregiverTitle}>
            {firstName} {getFirstLetter(lastName)}
          </Typography>
          {slot && (
            <Box className={classes.availableTimeContainer}>
              <Typography className={classes.availableTimeText}>
                Nästa tid:{" "}
                {getFormattedDateAndTime(slotConsideringDstCalculation)}
              </Typography>
            </Box>
          )}
          <Typography className={classes.seeAllTimesText}>
            Se fler tider{" >"}
          </Typography>
        </Box>
      </Box>
    </Card>
  )
}

export default CaregiverCard
